<template>

<div class="m-5">
  <b-overlay :opacity="opacity" :show="showOverlay" rounded="sm">
    <div>
       <b-row>
              <b-col md="8">
                <b-input 
                  id="box-number"
                  @keyup.enter.prevent="getDetails()"
                  v-model="form.box_number"
                  type="text"
                  placeholder="Please enter the Box Number">
                </b-input>
                <span class="text-danger">{{error}}</span>
              </b-col>
              <b-col md="2" class="mt-2 mt-sm-0">
                <b-button type="button" block class="btn-primary-spacing" variant="danger" :disabled="showOverlay" @click="getDetails()">Search</b-button>
              </b-col>
       </b-row>
    </div>
  </b-overlay>
    <hr>
    <div class="mt-5 col-md-12 transportaion_area_content text-center" v-if="firstTimeLoaded">
      <h1>
        Box number format:
      </h1>
      <p style="font-size:20px;letter-spacing:4px;" class="text-center">
        Box number starts with letters followed by numbers.
      </p>
    </div>
    <div class="mt-5 col-md-12 transportaion_area_content text-center" v-else-if="!firstTimeLoaded && !result.length">
      <h3 class="" style="color:#fc0509"> Shipping details unavailable</h3>
          <p style="font-size:20px;letter-spacing:4px;" > Possible reason/s:</p>
          <p style="font-size:20px;letter-spacing:4px;" > - Shipping details have not yet submitted.</p>
          <p style="font-size:20px;letter-spacing:4px;" > - Box number and/or lastname incorrect .</p>
    </div>
    <div class="mt-5" v-else>
      <b-container class="bv-example-row">
        <b-row>
          <b-col md="4" offset-md="4" class="text-center font-weight-bold">

            <p> {{result[1].InvoiceNum}} </p> 
            <!-- <br> -->
            <p> 
            <template v-if="result[1].CtrlNumUS">
              {{ result[1].CtrlNumUS }} 
            </template>
            <template v-else>N/A</template> 
            </p>
                <div v-if="result[1].DateDelivered">
                  <img src="/check.jpg" class="responsive" alt="Done">
                  <p> Delivered </p>
                  <template>
                    <p class="text-muted">
                    {{ result[1].DateDelivered }} 
                    </p>
                  </template>
                </div>
          </b-col>
        </b-row>
        <!-- row start -->
        <b-row>
          <b-col md="4">
            <b-card header="Origin" header-text-variant="primary" class="custom-card card-white shadow text-center">
              <b-card-text> 
                <template v-if="result[1].S_City">
                    {{ result[1].S_City }} 
                  </template>
                  <template v-else>N/A</template>  
              </b-card-text>
            </b-card>
          </b-col>
          <b-col md="4">
          </b-col>
          <b-col md="4">
            <b-card-group deck class="mb-3">
              <b-card header="Destination" header-text-variant="primary" class="custom-card card-white shadow text-center">
                <b-card-text> 
                  <template v-if="result[1].C_City">
                    {{ result[1].C_City }} 
                  </template>
                  <template v-else>N/A</template>  
                </b-card-text>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
      </b-container>

      <h1>{{ result.DateArrived }}</h1>
        <b-table striped bordered hover small responsive head-variant="dark" sticky-header class="scroll-to-me" :items="result[0]">
        </b-table>       
    </div>
</div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'App',
    data() {  
      return {
        showOverlay: false,
        blur: "1px",
        opacity: 0.00,
        error : "",
        result : "",
        firstTimeLoaded : true,
        base_url : "https://umactracking.net/api/umac-widget-details",
        form : {
          box_number : ''
        }
      }
    },
    methods: {
       scrollToElement() {
        const el = this.$el.getElementsByClassName('scroll-to-me')[0];
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({behavior: 'smooth'});
        }
      },
      getDetails: function () {
        this.showOverlay = true;
        let _this = this;
        _this.error = "";
        axios.get(_this.base_url, {
          params: {
            box_number: _this.form.box_number
          }
        })
        .then(function (response) {
          if (response.data) {
            _this.showOverlay = false;    
            setTimeout(() => {
              _this.scrollToElement();
              _this.form.box_number = '';
            }, 100);        
            _this.result = response.data;
            _this.firstTimeLoaded = false;
          }          
        })
        .catch(function (error) {
          _this.showOverlay = false;
          _this.result = "";
          _this.error = error.response.data.errors.box_number[0];
        });  
          // this.showOverlay = false;
      }
    }
  }
</script>
<style lang="scss">
.responsive {
  width: 15%;
  height: auto;
}
.btn-primary-spacing 
{
margin-right: 5px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #F32013;
}
.transportaion_area_content{
  padding-bottom:50px;
}
@media (min-width: 992px) {
  .transportaion_area_content{
    padding:120px;
  }
}
.mobile-top-margin{
  margin-bottom: 2px ;
}
.card-margin-top{
  margin-top: 10px;
}
.form-control:focus {
    color: #0a0a0a;
    border-color: #ff0000;
    border-color: #ff0000;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}
.custom-card {
  border-radius: 15px !important;
  &.card {
    &-dark{
        .card-header, .card-body { background: #343a40;color: white !important}
    }

    &-white{
          .card-header, .card-body { background: white;}
    }
}
  .card-header {
        // background: white;
        border:none;
        border-top-left-radius:15px;
        border-top-right-radius:15px;
  }
  .card-body {
      // background: white;
      border-bottom-left-radius:15px;
      border-bottom-right-radius:15px;
  }
}
</style>