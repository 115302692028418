import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'

// Vue.config.productionTip = false
// new Vue({
//   render: h => h(App),
// }).$mount('#app')



// import router from './router'
// import store from './store/index'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
Vue.use(vueCustomElement)
// App.store = store
// App.router = router
Vue.customElement('vue-widget', App)